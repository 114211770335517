::-webkit-scrollbar {
  width: 8.5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #1a1a1a;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(51, 51, 51);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(73, 73, 73);
}

/* RESPONSIVE MEDIAS */
.Media {
  height: auto;
  max-width: 100%;
  border-radius: 5px;
}

/* .Gallery {
  background: "#1c1c1c" !important;
} */

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* For timeline component override styling */
.MuiStepLabel-labelContainer {
  color: #fff !important;
}

/* For navbar component override styling */
.MuiDrawer-paper {
  background-color: #1a1a1a !important;
  color: whitesmoke !important;
}

.MuiAppBar-root {
  /* primary: #3f51b5 */
  /* TODO: figure out  */
  background-color: rgb(42, 42, 42) !important;
}

.MuiMobileStepper-dot .MuiMobileStepper-root {
  /* This doesn't work */
  background-color: '#fff' !important;
  color: '#fff' !important;
}

.zNav.header {
  font-family: Teko, sans-serif !important;
}

/* a {
  text-decoration: "none" !important;
}

a.visited {
  color: #3f51b5 !important;
} */

/* CSS TRANSITION GROUP */
.transition-enter {
  opacity: 0;
}
.transition-enter-active {
  opacity: 1;
  transition: opacity 2000ms;
}
.transition-exit {
  opacity: 1;
}
.transition-exit-active {
  opacity: 0;
  transition: opacity 2000ms;
}

/* https://stackoverflow.com/questions/44522299/css-only-acrylic-material-from-fluent-design-system */
.acrylic {
  padding: 4em 6em;
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  margin: 7px;
}

.wide-div {
  width: calc(100vw - 20px);
  margin-left: calc(-50vw + 50% + 10px);
}

/* href styling */
a.visited {
  color: #3489eb;
}

a {
  color: #3997ff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
